import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 30px;

	@media (min-width: 1200px) {
		font-size: 20px;
		line-height: 38px;
		width: 90%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		width: 80%;
	}
	&.full-width {
		width: 100%;
	}
`;

const TextSubpageDescription = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextSubpageDescription;
