import React, { Component } from 'react';

class BackgroundChange extends Component {
	completedArrayForBgChanges = [];

	initialColorSet = {};

	activeColorSet = {};

	activeModule = '';

	activeModuleScrollDown = '';

	activeModuleScrollUp = -1;

	scrollPosition = 0;

	componentDidMount() {
		this.initialColorSet = {
			bgColor: getComputedStyle(document.documentElement).getPropertyValue('--background-color'),
			logoColor: getComputedStyle(document.documentElement).getPropertyValue('--logo-color'),
			textColor: getComputedStyle(document.documentElement).getPropertyValue('--text-color'),
		};

		this.activeColorSet = this.initialColorSet;
		const { arrayForBgChanges } = this.props;
		const tempArr = [...arrayForBgChanges];
		tempArr.forEach((item) => {
			item.textColor = item.bgColor === 'black' ? 'rgba(222, 222, 223, 1)' : 'rgba(2, 2, 2, 1)';
			item.logoColor = item.bgColor === 'black' ? 'rgba(255,255,255,1)' : 'rgba(2, 2, 2, 1)';
		});

		setTimeout(() => {
			this.completedArrayForBgChanges = this.addOffsetsToArray(tempArr);
		}, 500);

		window.addEventListener('scroll', this.setBgColor);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.setBgColor);
	}

	setBgColor = () => {
		if (this.scrollPosition > (document.documentElement.scrollTop || document.body.scrollTop)) {
			this.changeBgOnScrollUp();
		} else {
			this.changeBgOnScrollDown();
		}
		this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
	};

	changeBgOnScrollDown = () => {
		for (let i = 0; i < this.completedArrayForBgChanges.length; i += 1) {
			if (
				this.scrollPosition >= this.completedArrayForBgChanges[i].offsetTop &&
				this.scrollPosition < this.completedArrayForBgChanges[i].offsetBottom
			) {
				this.activeModule = i;

				if (this.scrollPosition >= this.completedArrayForBgChanges[i].changeOffset) {
					if (this.completedArrayForBgChanges[i].bgColor === 'black') {
						this.changeBgToBlack();
					} else {
						this.changeBgToWhite();
					}
				}
			}
		}
	};

	changeBgOnScrollUp = () => {
		for (let i = 0; i < this.completedArrayForBgChanges.length; i += 1) {
			if (this.scrollPosition <= 0) {
				this.activeColorSet = this.initialColorSet;
			} else if (
				this.scrollPosition >= this.completedArrayForBgChanges[i].offsetTop &&
				this.scrollPosition < this.completedArrayForBgChanges[i].offsetBottom
			) {
				if (this.scrollPosition <= this.completedArrayForBgChanges[i].changeOffset) {
					if (this.completedArrayForBgChanges[i].bgColor === 'black') {
						this.changeBgToWhite();
					} else {
						this.changeBgToBlack();
					}
				}
			}
		}
	};

	changeBgToWhite = () => {
		document.documentElement.style.setProperty('--background-color', 'white');
		document.documentElement.style.setProperty('--text-color', 'rgba(2, 2, 2, 1)');
		document.documentElement.style.setProperty('--logo-color', 'rgba(2, 2, 2, 1)');
		document.documentElement.style.setProperty('--filter', '0');
	};

	changeBgToBlack = () => {
		document.documentElement.style.setProperty('--background-color', 'black');
		document.documentElement.style.setProperty('--text-color', 'rgba(222, 222, 223, 1)');
		document.documentElement.style.setProperty('--logo-color', 'rgba(255,255,255,1)');
		document.documentElement.style.setProperty('--filter', '1');
	};

	addOffsetsToArray = (items) => {
		const targetElements = items.map((item) => {
			return document.getElementById(item.id);
		});

		targetElements.map((el, i) => {
			const multiplier = items[i].multiplier ? items[i].multiplier : 0.5;
			items[i].changeOffset =
				el.id !== 'footer-el' ? el.offsetTop + multiplier * el.offsetHeight : el.offsetTop - 0.2 * el.offsetHeight;
			items[i].offsetTop = el.id !== 'footer-el' ? el.offsetTop : items[i].changeOffset - 100;
			items[i].offsetBottom = el.offsetTop + el.offsetHeight;
			return el.offsetTop;
		});

		return items;
	};

	render() {
		return <></>;
	}
}

export default BackgroundChange;
