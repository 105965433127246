import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import PageContainer from '../common/PageContainer';
import ButtonRectangle from '../common/ButtonRectangle';
import TextBanner from '../typography/TextBanner';
import getSlug from '../../utils/getSlug';

const StyledBackgroundWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	border-bottom: 1px solid ${({ theme }) => theme.colors.greyDarkLine};
	border-top: 1px solid ${({ theme }) => theme.colors.greyDarkLine};
	height: 100%;
	padding: 140px 0;
	width: 100%;

	@media ${({ theme }) => theme.device.w_425} {
		padding: 160px 0;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 150px 0;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding: 200px 0;
	}
`;

const StyledModuleWrapper = styled.div`
	width: 100%;
`;

const StyledContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

const StyledButtonWrapper = styled.div`
	margin-bottom: 10px;
	margin-top: 30px;
	position: relative;
	text-align: center;

	@media ${({ theme }) => theme.device.w_768} {
		margin-top: 10px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		margin-top: 30px;
	}
`;

const HeadingButton = ({ data, activeSite }) => {
	const moduleData = data?.modulNapiszDoNas?.[0];
	const buttonText = moduleData?.przyciskTekst || '';
	const heading = moduleData?.naglowek || '';
	const buttonHref = moduleData?.przyciskHref || '';
	const buttonLink = moduleData?.przyciskLink?.[0]?.slug || '';

	return (
		<PageContainer id="write-to-us" className="write-to-us">
			<StyledBackgroundWrapper>
				<StyledModuleWrapper>
					<Fade bottom>
						<StyledContentWrapper>
							<TextBanner className="grey-light write-us">{heading}</TextBanner>
							<StyledButtonWrapper>
								<ButtonRectangle className="not-change-color" to={`/${getSlug(buttonLink, activeSite)}`} href={buttonHref}>
									{buttonText}
								</ButtonRectangle>
							</StyledButtonWrapper>
						</StyledContentWrapper>
					</Fade>
				</StyledModuleWrapper>
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default connect(
	(state) => ({
		backgroundColorState: state.app.backgroundColorState,
	}),
	null,
)(HeadingButton);
