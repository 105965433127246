import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 30px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 44px;
	margin: 20px 0 0px;

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 40px;
		line-height: 60px;
		margin: 20px 0 40px;
	}

	@media (min-width: 1200px) {
		font-size: 48px;
		line-height: 70px;
		margin: 20px 0 40px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 62px;
		line-height: 84px;
		margin: 20px 0 40px;
	}

	&.product {
		@media ${({ theme }) => theme.device.w_1920} {
			font-size: 60px;
			line-height: 80px;
			margin: 0 0 30px;
		}
	}
`;

const TextSubpageHeadline = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextSubpageHeadline;
