import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import TextHeader from '../typography/TextHeader';

const StyledModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10vh 20px 0;
	@media (min-width: 768px) {
		margin: 10vh 0 0;
	}
`;

const StyledHeaderWrapper = styled.div`
	width: 100%;
`;

const StyledTableWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 8vh 0 15vh;
	width: 100%;
`;

const StyledTable = styled.table`
	color: ${({ theme }) => theme.colors.black};
	width: 100%;
	@media (min-width: 768px) {
		width: 600px;
	}
	@media (min-width: 1024px) {
		width: 625px;
	}
	@media (min-width: 1440px) {
		width: 880px;
	}
	tbody {
		&:first-child {
			tr {
				&:first-child {
					border-top: 2px solid ${({ theme }) => theme.colors.green};
				}
			}
		}
		tr {
			border: 2px solid ${({ theme }) => theme.colors.green};
			border-collapse: collapse;
			border-top: none;
			display: table;
			min-height: 80px;
			table-layout: fixed;
			width: 100%;
		}

		td {
			&.first-column {
				color: var(--text-color);
				font-size: 12px;
				font-weight: ${({ theme }) => theme.fontWeight.regular};
				line-height: 22px;
				padding: 10px 0 10px 20px;
				width: 60%;
				@media (min-width: 768px) {
					font-size: 16px;
					line-height: 30px;
					padding: 10px 0 10px 50px;
					width: 66%;
				}
			}
			&.second-column {
				color: var(--text-color);
				font-size: 14px;
				font-weight: ${({ theme }) => theme.fontWeight.black};
				line-height: 22px;
				padding-left: 10px;
				width: 40%;
				@media (min-width: 768px) {
					font-size: 20px;
					line-height: 28px;
					width: 34%;
				}
			}
		}
	}
`;

const ProductTable = ({ data }) => {
	const moduleData = data?.modulProduktTabelka?.[0] || [];
	const header = moduleData?.naglowek || '';
	const table = moduleData?.tabelka || [];
	const moduleId = data?.remoteId + data?.__typename || '';
	return (
		<PageContainer className="full-width">
			<StyledModuleWrapper id={moduleId}>
				<StyledHeaderWrapper className="inner-wrapper-large">
					<TextHeader className="table">{header}</TextHeader>
				</StyledHeaderWrapper>
				<StyledTableWrapper>
					<StyledTable>
						<tbody>
							{table.map((item, key) => {
								const parameterName = item?.nazwaParametru || '';
								const parameterValue = item?.wartoscParametru || '';
								const index = `index-${key}`;
								return (
									<tr key={index}>
										<td className="first-column">{parameterName}</td>
										<td className="second-column">{parameterValue}</td>
									</tr>
								);
							})}
						</tbody>
					</StyledTable>
				</StyledTableWrapper>
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default ProductTable;
