import React from 'react';
import styled from 'styled-components';
import TextSubpageHeadline from '../typography/TextSubpageHeadline';

const HeaderStyled = styled.h3`
	color: var(--text-color);
	margin: 0 auto;
	padding: 0 20px 50px;
	width: 100%;
	@media (min-width: 768px) {
		width: 600px;
		padding: 0 0 30px;
	}
	@media (min-width: 1024px) {
		width: 625px;
		padding: 0;
	}
	@media (min-width: 1440px) {
		width: 880px;
	}
`;

const SingleNewsHeader = ({ data }) => {
	const header = data?.naglowek || '';
	return (
		<HeaderStyled>
			<TextSubpageHeadline className="product">{header}</TextSubpageHeadline>
		</HeaderStyled>
	);
};

export default SingleNewsHeader;
