/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ seoData, globalSeo }) => {
	const seoTitle = seoData.seoTitle ? seoData.seoTitle : globalSeo.seoTitle ? globalSeo.seoTitle : '';
	const seoDescription = seoData.seoDescription ? seoData.seoDescription : globalSeo.seoDescription ? globalSeo.seoDescription : '';
	const seoKeywords = seoData.seoKeywordsSource
		? seoData.seoKeywordsSource
		: globalSeo.seoKeywordsSource
		? globalSeo.seoKeywordsSource
		: '';

	const seoFacebookTitle = seoData.facebookOpengraphTitle
		? seoData.facebookOpengraphTitle
		: globalSeo.facebookOpengraphTitle
		? globalSeo.facebookOpengraphTitle
		: '';
	const seoFacebookDescription = seoData.facebookOpengraphDescription
		? seoData.facebookOpengraphDescription
		: globalSeo.facebookOpengraphDescription
		? globalSeo.facebookOpengraphDescription
		: '';
	const seoFacebookImg =
		seoData.facebookOpengraphImage && seoData.facebookOpengraphImage.length > 0
			? seoData.facebookOpengraphImage[0].url
			: globalSeo.facebookOpengraphImage && globalSeo.facebookOpengraphImage.length > 0
			? globalSeo.facebookOpengraphImage[0].url
			: '';
	const seoFacebookImgDescription = seoData.facebookOpengraphImageDescription
		? seoData.facebookOpengraphImageDescription
		: globalSeo.facebookOpengraphImageDescription
		? globalSeo.facebookOpengraphImageDescription
		: '';

	const seoTwitterTitle = seoData.twitterTitle ? seoData.twitterTitle : globalSeo.twitterTitle ? globalSeo.twitterTitle : '';
	const seoTwitterDescription = seoData.twitterDescription
		? seoData.twitterDescription
		: globalSeo.twitterDescription
		? globalSeo.twitterDescription
		: '';
	const seoTwitterImg =
		seoData.twitterImage && seoData.twitterImage.length > 0
			? seoData.twitterImage[0].url
			: globalSeo.twitterImage && globalSeo.twitterImage.length > 0
			? globalSeo.twitterImage[0].url
			: '';
	const seoTwitterImgDescription = seoData.twitterImageDescription
		? seoData.twitterImageDescription
		: globalSeo.twitterImageDescription
		? globalSeo.twitterImageDescription
		: '';

	return (
		<Helmet
			title={seoTitle}
			meta={[
				{
					name: `description`,
					content: seoDescription,
				},
				{
					name: `keywords`,
					content: seoKeywords,
				},
				{
					property: `og:title`,
					content: seoFacebookTitle,
				},
				{
					property: `og:description`,
					content: seoFacebookDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: seoFacebookImg,
				},
				{
					property: `og:image:secure_url`,
					content: seoFacebookImg,
				},
				{
					property: `og:image:alt`,
					content: seoFacebookImgDescription,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:title`,
					content: seoTwitterTitle,
				},
				{
					name: `twitter:description`,
					content: seoTwitterDescription,
				},
				{
					property: `twitter:image`,
					content: seoTwitterImg,
				},
				{
					property: `twitter:image:alt`,
					content: seoTwitterImgDescription,
				},
			]}
		/>
	);
};

export default SEO;
