import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContainer from '../common/PageContainer';

import TextSubpageDescription from '../typography/TextSubpageDescription';
import TextSubpageHeadline from '../typography/TextSubpageHeadline';

import getSlug from '../../utils/getSlug';

const StyledBackgroundWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	background-image: url('${({ photo }) => photo}');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 80vh;
	padding-top: 150px;
	width: 100%;
	@media (min-width: 500px) {
		height: 60vh;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 20% 0 0;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		height: 50vh;
		@media (orientation: landscape) {
			height: 80vh;
		}
	}

	@media (min-width: 1200px) {
		padding: 16% 0 5%;
		height: 80vh;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		//padding: 12% 0;
		padding: 0;
	}
`;

const StyledModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	width: 100%;
	@media (min-width: 1100px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const StyledHeadlineWrapper = styled.div`
	height: auto;
	margin-bottom: 5%;
	width: 100%;
	@media ${({ theme }) => theme.device.w_768} {
	}

	@media ${({ theme }) => theme.device.w_1024} {
		width: 80%;
	}

	@media (min-width: 1100px) {
		margin-bottom: 0%;
		width: 60%;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		padding-top: 23vh;
		width: 55%;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		width: 45%;
		padding-top: 23vh;
	}
	@media (min-width: 2550px) {
		padding-top: 26vh;
	}
`;

const StyledLink = styled(Link)`
	color: white;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 12px;

	@media (min-width: 500px) {
		font-size: 12px;
		line-height: 20px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 14px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 18px;
		line-height: 50px;
	}
`;

const StyledBreadCrumb = styled.h4`
	color: white;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 18px;

	@media (min-width: 500px) {
		font-size: 12px;
		line-height: 20px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 17px;
		line-height: 50px;
	}
`;

const StyledBreadCrumbsWrapper = styled.div`
	align-items: center;
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 18px;

	a {
		@media (min-width: 500px) {
			font-size: 12px;
			line-height: 20px;
		}
		@media ${({ theme }) => theme.device.w_768} {
			font-size: 12px;
			line-height: 28px;
		}

		@media (min-width: 1200px) {
			font-size: 16px;
			line-height: 36px;
		}

		@media ${({ theme }) => theme.device.w_1920} {
			font-size: 17px;
			line-height: 50px;
		}
	}
`;

const HeadlineBackground = ({ data, activeSite, allProductPageSlug, productData }) => {
	const moduleData = data?.modulNaglowekTlo || [];
	const moduleId = data?.remoteId + data?.__typename || '';

	let headerBig = '';

	let description = '';
	let photo = '';

	// eslint-disable-next-line array-callback-return
	moduleData.map((item) => {
		if (item.__typename === 'Craft_modulNaglowekTlo_naglowek_BlockType') {
			headerBig = item?.naglowekDuzy || '';

			description = item?.opis || '';
		}
		if (item.__typename === 'Craft_modulNaglowekTlo_tlo_BlockType') {
			photo = item?.tlo?.[0]?.url || '';
		}
	});

	// const chooseCategoryBigLetter = categorySlug.charAt(0).toUpperCase() + categorySlug.slice(1);
	// const search = '-';
	// const searchRegExp = new RegExp(search, 'g'); // Throws SyntaxError
	// const replaceWith = ' ';
	// const categoryName = chooseCategoryBigLetter.replace(searchRegExp, replaceWith);

	return (
		<PageContainer className="full-width">
			<StyledBackgroundWrapper photo={photo} id={moduleId}>
				<StyledModuleWrapper className="inner-wrapper-large padding-right">
					<StyledHeadlineWrapper>
						<StyledBreadCrumbsWrapper>
							<StyledLink to={`/${getSlug(allProductPageSlug, activeSite)}`}>
								{activeSite === 'en' ? '_Products' : '_Produkty'}
							</StyledLink>
							<StyledBreadCrumb>_{productData.title}</StyledBreadCrumb>
						</StyledBreadCrumbsWrapper>

						<TextSubpageHeadline className="white product">{headerBig}</TextSubpageHeadline>
						<TextSubpageDescription className="white">{description}</TextSubpageDescription>
					</StyledHeadlineWrapper>
				</StyledModuleWrapper>
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default HeadlineBackground;
