/*eslint-disable*/

import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import TextSubpageDescription from '../typography/TextSubpageDescription';
import TextSubpageHeadline from '../typography/TextSubpageHeadline';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';

const StyledBackgroundWrapper = styled.div`
	// background-color: ${({ theme }) => theme.colors.white};
	height: 100%;
	width: 100%;
	padding-top: 150px;
	@media (min-width: 500px) {
		height: 80vh;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 20% 0 0;
	}
	@media (min-width: 1200px) {
		padding: 16% 0 5%;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		//padding: 12% 0;
		padding: 0;
	}
`;

const StyledModuleWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	@media (min-width: 599px) {
		@media (orientation: landscape) {
			flex-direction: row;
			align-items: flex-start;
		}
	}
	@media ${({ theme }) => theme.device.w_1024} {
		@media (orientation: landscape) {
			flex-direction: row;
			align-items: flex-start;
		}
	}
	@media (min-width: 1100px) {
		align-items: unset;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const StyledHeadlineWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 5%;
	@media ${({ theme }) => theme.device.w_768} {
		@media (orientation: landscape) {
			width: 70%;
		}
	}

	@media ${({ theme }) => theme.device.w_1024} {
		width: 80%;
	}

	@media (min-width: 1100px) {
		margin-bottom: 0%;
		width: 60%;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		padding-top: 23vh;
		width: 55%;
	}

	@media (min-width: 1800px) {
		width: 60%;
		padding-top: 23vh;
	}
	@media (min-width: 2550px) {
		padding-top: 26vh;
		width: 45%;
	}
`;

const StyledPhotoWrapper = styled.div`
	width: 60%;
	height: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 5%;

	@media (min-width: 500px) {
		margin-bottom: 0%;
	}

	@media ${({ theme }) => theme.device.w_768} {
		width: 60%;
		@media (orientation: landscape) {
			width: 30%;
		}
	}

	@media ${({ theme }) => theme.device.w_1024} {
		width: 80%;
		@media (orientation: landscape) {
			width: 20%;
		}
	}

	@media (min-width: 1100px) {
		width: 40%;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		width: 45%;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		width: 40%;
	}
`;

const StyledPhoto = styled.img`
	width: 100%;
	height: auto;
`;

const StyledLink = styled(Link)`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 18px;
	color: black;

	@media (min-width: 500px) {
		font-size: 12px;
		line-height: 20px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 17px;
		line-height: 50px;
	}
`;

const StyledBreadCrumb = styled.h4`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 18px;
	color: black;

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 17px;
		line-height: 50px;
	}
`;

const StyledBreadCrumbsWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const HeadlinePhoto = ({ data, activeSite, productData, allProductPageSlug, isDifferentPage, pageTitleForBreadCrumbs }) => {
	const moduleData = data?.modulNaglowekZdjecie || [];
	const moduleId = data?.remoteId + data?.__typename || '';

	let headerBig = '';
	let description = '';
	let photo = '';

	// eslint-disable-next-line array-callback-return
	moduleData.map((item) => {
		if (item.__typename === 'Craft_modulNaglowekZdjecie_naglowek_BlockType') {
			headerBig = item?.naglowekDuzy || '';
			description = item?.opis || '';
		}
		if (item.__typename === 'Craft_modulNaglowekZdjecie_zdjecie_BlockType') {
			photo = item?.zdjecie?.[0]?.url || '';
		}
	});
	const singleProductUri = productData?.uri || '';
	const categoryProductUri = productData?.remoteParent?.uri || '';
	const categoryBreadCrumb = productData?.id === '1256' ? singleProductUri : categoryProductUri;
	const breadcrumb = '_' + pageTitleForBreadCrumbs;
	return (
		<PageContainer>
			<StyledBackgroundWrapper id={moduleId}>
				<StyledModuleWrapper className="inner-wrapper-large padding-right">
					<StyledHeadlineWrapper>
						<StyledBreadCrumbsWrapper>
							{!isDifferentPage && (
								<StyledLink to={`/${getSlug(allProductPageSlug, activeSite)}`}>
									{activeSite === 'en' ? '_Products' : '_Produkty'}
								</StyledLink>
							)}
							{!isDifferentPage && (
								<StyledLink to={`/${getSlug(categoryBreadCrumb, activeSite)}`}>
									_{productData?.remoteParent?.title}
								</StyledLink>
							)}
							<StyledBreadCrumb>{isDifferentPage ? breadcrumb : `_${productData.title}`}</StyledBreadCrumb>
						</StyledBreadCrumbsWrapper>
						{/*<TextSubpageHeadlineSmall className="black product">{headerSmall}</TextSubpageHeadlineSmall>*/}
						<TextSubpageHeadline className="black product">{headerBig}</TextSubpageHeadline>
						<TextSubpageDescription className="black">{description}</TextSubpageDescription>
					</StyledHeadlineWrapper>

					<StyledPhotoWrapper>
						<StyledPhoto src={photo} />
					</StyledPhotoWrapper>
				</StyledModuleWrapper>
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default HeadlinePhoto;
