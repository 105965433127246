/*eslint-disable*/
import React, { Component } from 'react';
import styled from 'styled-components';
import { Scrollbar } from 'react-scrollbars-custom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import manageScroll from '../../utils/manageScroll';
import { connect } from 'react-redux';

const GalleryStyled = styled.div`
	margin-top: -4px;
	margin-bottom: 100px;
	height: 75vw;
	@media (min-width: 425px) {
		height: 37.6vw;
	}
	@media (min-width: 1024px) {
		height: 24.75vw;
	}
	* {
		cursor: none !important;
	}
	.TrackXStyled {
		opacity: 0;
		left: 50% !important;
		transform: translateX(-50%) !important;
		width: calc(100% - 40px) !important;
		background: ${({ theme }) => theme.colors.black} !important;
		@media (min-width: 425px) {
			width: calc((100% - 40px) * 10 / 12) !important;
		}
		@media (min-width: 768px) {
			width: calc((100% - 124px) * 10 / 12) !important;
		}
		@media (min-width: 1440px) {
			width: calc((100% - 150px) * 10 / 12) !important;
		}
	}
	.ScrollbarsCustom-Thumb {
		background: ${({ theme }) => theme.colors.green} !important;
	}
`;

const GalleryCarouselWrapper = styled.div`
	display: flex;
`;

const ImageWrapper = styled.div`
	position: relative;
	//height: 100%;
	//width: 33vw;

	// &:hover {
	// 	&:before {
	// 		content: '';
	// 		position: absolute;
	// 		width: 100%;
	// 		height: 100%;
	// 		top: 0;
	// 		left: 0;
	// 		background-color: ${({ theme }) => theme.colors.green};
	// 		opacity: .65;
	// 		z-index: 1;
	// 	}
	// 	&::after {
	// 		content: '';
	// 		position: absolute;
	// 		width: 100%;
	// 		height: 100%;
	// 		top: 0;
	// 		background-position: center center;
	// 		left: 0;
	// 		z-index: 1;
	// 		background-image: ${'url(' + Magnifier + ')'};
	// 		background-repeat: no-repeat;
	// 	}
	// }
`;

const StyledImageWrapper = styled.div`
	padding-bottom: 75%;
	position: relative;
	overflow: hidden;
	width: 100vw;
	@media (min-width: 425px) {
		width: 50vw;
	}
	@media (min-width: 1024px) {
		width: 33vw;
	}
`;

const StyledBackgroundImageWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	transition: transform 0.3s ease-in-out;
	&:hover {
		transform: scale(1.05);
	}
`;

const customStyles = {
	overlay: {
		zIndex: '11',
	},
};

class GalleryModule extends Component {
	state = {
		dragging: false,
		photoIndex: 0,
		isOpen: false,
	};
	customCursor;

	componentDidMount() {
		setTimeout(() => {
			this.customCursor = document.getElementById('custom-cursor-gallery');
		}, 0);
		const slider = document.getElementById('gallery-scrollbar-content').firstElementChild.firstElementChild;
		let isDown = false;
		let startX;
		let scrollLeft;

		slider.addEventListener('mousedown', (e) => {
			this.setState({ dragging: false });
			isDown = true;
			slider.classList.add('active');
			startX = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mousemove', (e) => {
			this.setState({ dragging: true });
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - slider.offsetLeft;
			const walk = (x - startX) * 1; //scroll-fast
			slider.scrollLeft = scrollLeft - walk;
		});
	}

	openImgInModal(key) {
		if (!this.state.dragging) this.setState({ isOpen: true, photoIndex: key });
	}

	handleMouseMoveOnSlider = (e) => {
		const { isMobileDevice } = this.state;
		// this.customCursor.style.display = 'flex';
		// const rect = e.currentTarget.getBoundingClientRect();
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		if (!isMobileDevice) {
			this.customCursor.style.top = `${offsetY}px`;
			this.customCursor.style.left = `${offsetX}px`;
		}
	};

	render() {
		const { data } = this.props;

		const moduleData = data?.modulGaleria || [];
		const { photoIndex, isOpen } = this.state;
		manageScroll(isOpen);
		const photoArray = [];
		if (moduleData.length > 0) {
			moduleData.map((photo) => {
				photoArray.push(photo.url);
			});
		}

		return (
			<GalleryStyled
				data-cursor="green-circle-gallery"
				className="custom-cursor-animation custom-slider"
				onMouseMove={this.handleMouseMoveOnSlider}
			>
				<div>
					{isOpen && (
						<Lightbox
							mainSrc={photoArray[photoIndex]}
							nextSrc={photoArray[(photoIndex + 1) % photoArray.length]}
							prevSrc={photoArray[(photoIndex + photoArray.length - 1) % photoArray.length]}
							reactModalStyle={customStyles}
							onCloseRequest={() => {
								this.setState({ isOpen: false });
							}}
							onMovePrevRequest={() =>
								this.setState({
									photoIndex: (photoIndex + photoArray.length - 1) % photoArray.length,
								})
							}
							onMoveNextRequest={() =>
								this.setState({
									photoIndex: (photoIndex + 1) % photoArray.length,
								})
							}
						/>
					)}
				</div>
				<Scrollbar
					style={{ width: '100%' }}
					id="gallery-scrollbar-content"
					noScrollY={true}
					trackXProps={{
						renderer: (props) => {
							const { elementRef, ...restProps } = props;
							return <span {...restProps} ref={elementRef} className="TrackXStyled" />;
						},
					}}
				>
					<GalleryCarouselWrapper>
						{photoArray.length > 0 &&
							photoArray.map((photo, key) => (
								<ImageWrapper
									onClick={() => {
										this.openImgInModal(key);
									}}
									key={key}
								>
									<StyledImageWrapper>
										<StyledBackgroundImageWrapper bgImg={photo} />
									</StyledImageWrapper>
								</ImageWrapper>
							))}
					</GalleryCarouselWrapper>
				</Scrollbar>
			</GalleryStyled>
		);
	}
}

export default GalleryModule;
