import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	color: ${({ theme }) => theme.colors.green};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	line-height: 22px;
	text-align: center;
	text-transform: uppercase;
	&.hidden {
		visibility: hidden;
	}
	@media ${({ theme }) => theme.device.w_1280} {
		// color: ${({ theme }) => theme.colors.white};
		color: var(--text-color);
	}
`;

const TextButton = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextButton;
