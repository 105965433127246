import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import SingleNewsHeader from '../singleNews/SingleNewsHeader';
import SingleNewsDescription from '../singleNews/SingleNewsDescription';

const StyledModuleWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding-bottom: 10vh;
	width: 100%;
`;

const HeaderDescription = ({ data }) => {
	const moduleData = data?.modulNaglowekOpis?.[0] || [];
	const header = moduleData?.naglowek || '';
	const description = moduleData?.opis || '';
	return (
		<PageContainer>
			<StyledModuleWrapper>
				{header && <SingleNewsHeader data={moduleData} />}
				{description && <SingleNewsDescription data={moduleData} />}
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default HeaderDescription;
