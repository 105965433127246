import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	color: var(--text-color);
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 28px;
	transition: color 0.4s ease-in-out;
	&.white {
		color: ${({ theme }) => theme.colors.white};
	}
	&.workflow {
		font-size: 16px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		line-height: 26px;
		text-align: center;
		text-transform: uppercase;
		transition: color 0.4s ease-in-out;
		@media ${({ theme }) => theme.device.w_425} {
			font-size: 15px;
			line-height: 26px;
		}
		@media ${({ theme }) => theme.device.w_768} {
			font-size: 20px;
			line-height: 36px;
			text-align: left;
		}
		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 22px;
			line-height: 40px;
			text-align: left;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			font-size: 26px;
			line-height: 42px;
			text-align: center;
		}
	}

	@media ${({ theme }) => theme.device.w_425} {
		font-size: 16px;
		line-height: 30px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 17px;
		line-height: 38px;
	}

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 23px;
		line-height: 50px;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		font-size: 27px;
		line-height: 60px;
	}
`;

const TextDescription = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextDescription;
