import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	//padding: 0 30px;
	margin: 0 auto;
	max-width: 100%;
	&.black {
		background-color: ${({ theme }) => theme.colors.black};
	}
	&.write-to-us {
		background-color: ${({ theme }) => theme.colors.black};
		@media ${({ theme }) => theme.device.w_768} {
			padding: 0 80px;
		}
	}
	&.full-width {
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: 100%;
	}
	.inner-wrapper-large {
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
		@media ${({ theme }) => theme.device.w_425} {
			padding-left: calc((100% - 40px) * 1 / 12 + 20px);
			padding-right: calc((100% - 40px) * 1 / 12 + 20px);
		}
		@media ${({ theme }) => theme.device.w_768} {
			padding-left: calc((100% - 124px) * 1 / 12 + 62px);
			padding-right: calc((100% - 124px) * 1 / 12 + 62px);
		}

		@media ${({ theme }) => theme.device.w_1024} {
			padding-left: calc((100% - 124px) * 1 / 12 + 62px);
			padding-right: calc((100% - 124px) * 1 / 12 + 62px);
		}
		@media ${({ theme }) => theme.device.w_1440} {
			padding-left: calc((100% - 150px) * 1 / 12 + 75px);
			padding-right: calc((100% - 150px) * 1 / 12 + 75px);
		}
		&.banner-home-slider {
			padding-left: 30px;
			padding-right: 30px;
			@media ${({ theme }) => theme.device.w_425} {
				padding-left: calc((100% - 60px) * 1 / 12 + 30px);
				padding-right: calc((100% - 60px) * 1 / 12 + 30px);
			}
			@media ${({ theme }) => theme.device.w_768} {
				padding-left: calc((100% - 124px) * 1 / 12 + 62px);
				padding-right: calc((100% - 124px) * 1 / 12 + 62px);
			}
			@media ${({ theme }) => theme.device.w_1024} {
				padding-left: calc((100% - 124px) * 1 / 12 + 62px);
				padding-right: calc((100% - 124px) * 1 / 12 + 62px);
			}
			@media ${({ theme }) => theme.device.w_1440} {
				padding-left: calc((100% - 150px) * 1.2 / 12 + 75px);
				padding-right: calc((100% - 150px) * 1.2 / 12 + 75px);
			}
		}
	}
	.inner-wrapper-medium {
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
		@media ${({ theme }) => theme.device.w_425} {
			padding-left: calc((100% - 40px) * 1 / 12 + 20px);
			padding-right: calc((100% - 40px) * 1 / 12 + 20px);
		}
		@media ${({ theme }) => theme.device.w_768} {
			padding-left: calc((100% - 124px) * 2 / 12 + 62px);
			padding-right: calc((100% - 124px) * 2 / 12 + 62px);
		}
		@media ${({ theme }) => theme.device.w_1440} {
			padding-left: calc((100% - 150px) * 2 / 12 + 75px);
			padding-right: calc((100% - 150px) * 2 / 12 + 75px);
		}
		&.padding-right {
			@media ${({ theme }) => theme.device.w_425} {
				padding-right: calc((100% - 40px) * 0.5 / 12 + 20px);
			}
			@media ${({ theme }) => theme.device.w_768} {
				padding-right: calc((100% - 124px) * 1 / 12 + 62px);
			}
			@media ${({ theme }) => theme.device.w_1440} {
				padding-right: calc((100% - 150px) * 1 / 12 + 75px);
			}
		}
		&.news {
			@media ${({ theme }) => theme.device.w_768} {
				padding-left: calc((100% - 124px) * 1 / 12 + 62px);
				padding-right: calc((100% - 124px) * 1 / 12 + 62px);
			}
			@media ${({ theme }) => theme.device.w_1440} {
				padding-left: calc((100% - 150px) * 2 / 12 + 75px);
				padding-right: calc((100% - 150px) * 2 / 12 + 75px);
			}
		}
		&.padding-right-none {
			padding-right: 0;
		}
	}
	.inner-wrapper-small {
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
		@media ${({ theme }) => theme.device.w_425} {
			padding-left: calc((100% - 40px) * 1 / 12 + 20px);
			padding-right: calc((100% - 40px) * 1 / 12 + 20px);
		}
		@media ${({ theme }) => theme.device.w_768} {
			padding-left: calc((100% - 124px) * 2 / 12 + 62px);
			padding-right: calc((100% - 124px) * 1 / 12 + 62px);
		}
		@media ${({ theme }) => theme.device.w_1440} {
			padding-left: calc((100% - 150px) * 3 / 12 + 75px);
			padding-right: calc((100% - 150px) * 3 / 12 + 75px);
		}
		&.padding-right {
			@media ${({ theme }) => theme.device.w_425} {
				padding-right: calc((100% - 40px) * 1 / 12 + 20px);
			}
			@media ${({ theme }) => theme.device.w_768} {
				padding-right: calc((100% - 124px) * 1 / 12 + 62px);
			}
			@media ${({ theme }) => theme.device.w_1440} {
				padding-right: calc((100% - 150px) * 1 / 12 + 75px);
			}
		}
	}
`;

const PageContainer = ({ children, className, id }) => {
	return (
		<Container className={className} id={id}>
			{children}
		</Container>
	);
};

export default PageContainer;
