import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import TextButton from '../typography/TextButton';

const StyledButtonContainer = styled.div`
	background-color: transparent;
	display: inline-block;
	outline: 0;

	&.hover {
		.black {
			filter: unset;
		}
		.hover-class-text-wrapper {
			transform: translate3d(0, calc(-50% - 8px), 0);
		}
		.hover-class-text {
			color: ${({ theme }) => theme.colors.green};
		}
		.hover-class-line {
			background-color: transparent;
			&::after {
				background-color: ${({ theme }) => theme.colors.green};
				height: 6px;
				transform: translate3d(-16px, 0, 0);
				width: 6px;
			}
			&::before {
				background-color: ${({ theme }) => theme.colors.green};
				height: 6px;
				transform: translate3d(16px, 0, 0);
				width: 6px;
			}
		}
	}
`;

const StyledButtonBox = styled.button`
	background-color: transparent;
	border: none;
	display: block;
	margin: 0;
	outline: 0;
	//pointer-events: none;
	position: relative;

	// &.black {
	// 	filter: unset;
	//
	// 	@media ${({ theme }) => theme.device.w_1280} {
	// 		filter: invert(1);
	// 	}
	// }
	// &.white {
	// 	filter: unset;
	// }
	&.workflow-desktop {
		display: none;
		@media ${({ theme }) => theme.device.w_1024} {
			display: block;
		}
	}
	&.workflow-mobile {
		display: block;
		@media ${({ theme }) => theme.device.w_768} {
			display: none;
		}
	}
	&:after {
		border: 2px;
		content: '';
		height: 35px;
		position: absolute;
		width: 35px;
	}
`;

const StyledButtonLine = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.green};
	display: flex;
	height: 1px;
	justify-content: center;
	margin: 8px 0;
	position: relative;
	width: 100%;
	@media (min-width: 768px) {
		//background-color: ${({ theme }) => theme.colors.white};
		background-color: var(--text-color);
	}
	&.hover-class-line {
		background-color: ${({ theme }) => theme.colors.green};
		transition: transform, background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
		@media ${({ theme }) => theme.device.w_1280} {
			//background-color: ${({ theme }) => theme.colors.white};
			background-color: var(--text-color);
		}
	}
	&::after {
		background-color: ${({ theme }) => theme.colors.green};
		border-radius: 100%;
		content: '';
		height: 5px;
		left: 0;
		position: absolute;
		transition: transform 0.4s cubic-bezier(0.33, 1, 0.68, 1);
		width: 5px;
		@media ${({ theme }) => theme.device.w_1280} {
			//background-color: ${({ theme }) => theme.colors.white};
			background-color: var(--text-color);
		}
	}
	&::before {
		background-color: ${({ theme }) => theme.colors.green};
		border-radius: 100%;
		content: '';
		height: 5px;
		position: absolute;
		right: 0;
		transition: transform 0.4s cubic-bezier(0.33, 1, 0.68, 1);
		width: 5px;
		@media ${({ theme }) => theme.device.w_1280} {
			//background-color: ${({ theme }) => theme.colors.white};
			background-color: var(--text-color);
		}
	}
`;

const StyledTextWrapper = styled.div`
	color: var(--text-color);
	padding: 0;
	width: 160px;
	.hover-class-text {
		width: 100%;
	}
	&.hover-class-text-wrapper {
		align-items: center;
		display: flex;
		justify-items: center;
		transform: translate3d(0, 0, 0);
		transition: transform 0.4s cubic-bezier(0.33, 1, 0.68, 1);
	}
`;

const ButtonCircle = ({ className, href, children, to }) => {
	return (
		<StyledButtonContainer className="custom-cursor-animation">
			{href && (
				<StyledButtonBox className={` ${className}`} href={href} as="a" target="_blank" rel="noopener noreferrer">
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text hidden ">{children}</TextButton>
					</StyledTextWrapper>
					<StyledButtonLine className="  hover-class-line " />
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text ">{children}</TextButton>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!href && to && (
				<StyledButtonBox className={` ${className}`} as={Link} to={to}>
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text hidden ">{children}</TextButton>
					</StyledTextWrapper>
					<StyledButtonLine className="  hover-class-line " />
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text ">{children}</TextButton>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!to && !href && (
				<StyledButtonBox className={` ${className}`}>
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text hidden ">{children}</TextButton>
					</StyledTextWrapper>
					<StyledButtonLine className="  hover-class-line " />
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextButton className="  hover-class-text ">{children}</TextButton>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}
		</StyledButtonContainer>
	);
};

export default ButtonCircle;
