import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import TextDescription from '../typography/TextDescription';
import ButtonCircle from '../common/ButtonCircle';
import PageContainer from '../common/PageContainer';
import getSlug from '../../utils/getSlug';

const StyledModuleWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	padding: 50px 0;
	width: 100%;
	@media ${({ theme }) => theme.device.w_768} {
		padding: 30px 0 100px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding: 30px 0;
		flex-direction: row;
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
`;

const StyledTextWrapper = styled.div`
	padding: 40px 20px 16px 20px;
	width: 100%;
	@media ${({ theme }) => theme.device.w_425} {
		padding: 110px 0 24px;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 150px 0 80px 0;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		width: 80%;
		padding: 270px 0;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		width: 67%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		width: 55%;
	}
`;

const StyledButtonWrapper = styled.div`
	position: relative;
	text-align: right;
	@media ${({ theme }) => theme.device.w_1440} {
		text-align: center;
	}
`;

const DescriptionButton = ({ data, activeSite }) => {
	const moduleData = data?.modulOpis?.[0];
	const buttonText = moduleData?.przyciskTekst || '';
	const description = moduleData?.opis || '';
	const buttonHref = moduleData?.przyciskHref || '';
	const buttonLink = moduleData?.przyciskLink?.[0]?.slug || '';
	const moduleId = data?.remoteId + data?.__typename || '';

	return (
		<PageContainer className="full-width">
			<StyledModuleWrapper className="inner-wrapper-medium padding-right" id={moduleId}>
				<Fade bottom>
					<StyledTextWrapper>
						<TextDescription className="white">{description}</TextDescription>
					</StyledTextWrapper>
					{(buttonLink.length > 0 || buttonHref) && (
						<StyledButtonWrapper>
							<ButtonCircle to={`/${getSlug(buttonLink, activeSite)}`} href={buttonHref}>
								{buttonText}
							</ButtonCircle>
						</StyledButtonWrapper>
					)}
				</Fade>
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default DescriptionButton;
