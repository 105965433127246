import React from 'react';
import styled from 'styled-components';
import TextSubpageDescription from '../typography/TextSubpageDescription';

const HeaderStyled = styled.h3`
	color: var(--text-color);
	margin: 0 auto 60px;
	padding: 0 20px;
	@media (min-width: 768px) {
		padding: 0;
		width: 600px;
	}
	@media (min-width: 1024px) {
		width: 625px;
	}
	@media (min-width: 1440px) {
		width: 880px;
		margin-bottom: 100px;
	}
	a {
		color: ${({ theme }) => theme.colors.green};
		position: relative;
		&:before {
			background-color: ${({ theme }) => theme.colors.green};
			bottom: 0;
			content: '';
			height: 2px;
			left: 0;
			position: absolute;
			transform: scaleX(0);
			transform-origin: 23%;
			transition: transform 0.3s ease-in-out;
			width: 100%;
		}
		&:hover {
			&:before {
				transform: scaleX(1);
				width: 100%;
			}
		}
	}

	ul {
		li {
			padding-left: 15px;
			position: relative;

			&::before {
				content: '-';
				left: 0;
				position: absolute;
				top: 0;
			}
		}
	}
`;

const SingleNewsDescription = ({ data }) => {
	const description = data?.opis || '';
	const moduleId = data?.id + data?.__typename || '';

	return (
		<HeaderStyled id={moduleId}>
			<TextSubpageDescription className="full-width ">{description}</TextSubpageDescription>
		</HeaderStyled>
	);
};

export default SingleNewsDescription;
