import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import PageContainer from '../common/PageContainer';
import getSlug from '../../utils/getSlug';
import TextHeader2 from '../typography/TextHeader2';
import TextDescription2 from '../typography/TextDescription2';

const StyledModuleWrapper = styled.div`
	margin: 160px auto 320px;
	padding: 0 10px;
	text-align: center;
	width: 100%;
	@media (min-width: 768px) {
		width: 560px;
	}
	@media (min-width: 1024px) {
		width: 800px;
	}
	@media (min-width: 1440px) {
		width: 1060px;
	}
`;

const StyledModuleWrapperForAnimation = styled.div`
	position: relative;
`;

const CircleAnimationElement = styled.div`
	-webkit-font-smoothing: antialiased;
	backface-visibility: hidden;
	border: 1px solid white;
	border-radius: 50%;
	display: none;
	left: -30px;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: transform 0.6s ease-out;
	will-change: transform;
	@media (min-width: 768px) {
		display: block;
		width: 280px;
		height: 280px;
	}
	@media (min-width: 1024px) {
		width: 400px;
		height: 400px;
	}
	@media (min-width: 1440px) {
		width: 530px;
		height: 530px;
	}
`;

const StyledProductsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.header {
		text-align: start;
	}
	.description {
		text-align: start;
	}
	&:nth-of-type(2n) {
		@media (min-width: 768px) {
			.product-card {
				margin: 0 0 0 auto;
				align-items: flex-end;
			}
			.header {
				text-align: end;
			}
			.description {
				text-align: end;
			}
		}
		@media (min-width: 1024px) {
			.header-description-wrapper {
				flex-direction: row-reverse;
			}
		}
	}

	&:hover {
		.header {
			color: ${({ theme }) => theme.colors.green};
		}
	}
`;

const StyledProductCard = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	@media (min-width: 768px) {
		height: 280px;
		align-items: flex-start;
		width: 50%;
	}
	@media (min-width: 1024px) {
		height: 400px;
	}

	@media (min-width: 1440px) {
		height: 610px;
	}
`;

const StyledImageWrapper = styled.div`
	border-radius: 50%;
	clip-path: content-box;
	height: 200px;
	overflow: hidden;
	position: relative;
	width: 200px;
	@media (min-width: 768px) {
		width: 280px;
		height: 280px;
	}
	@media (min-width: 1024px) {
		width: 400px;
		height: 400px;
	}
	@media (min-width: 1440px) {
		width: 530px;
		height: 530px;
	}
`;

const StyledImageBg = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: transform 0.6s ease-out;
	width: 100%;
`;
const StyledDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	@media (min-width: 1024px) {
		width: calc(100% - 65px);
		max-width: 350px;
	}
`;

const StyledTextBelowImageWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 20px;
	width: 100%;

	@media (min-width: 768px) {
		padding-top: 50px;
	}
	@media (min-width: 1024px) {
		flex-direction: row;
		padding-top: 80px;
	}
	@media (min-width: 1440px) {
		padding-top: 80px;
	}
`;

const StyledNumberWrapper = styled.div`
	width: 100%;
	@media (min-width: 1024px) {
		width: 65px;
	}
`;

const StyledTextHeader2Wrapper = styled.div`
	margin-bottom: 20px;
	text-align: start;
`;

const StyledLink = styled(Link)`
	color: white;
	font-size: 50px;
	text-align: center;
`;

const CategoriesProducts = ({ productData, activeSite }) => {
	const [animatedProduct, setAnimatedProduct] = useState(0);
	const [customCursor, setCustomCursor] = useState(' ');

	const productsForAnimation = [];
	let scrollPosition = 0;
	// let animatedEl = '';
	let offsetDifference = 0;
	const circleElRef = useRef(null);
	const refs = useRef([]);
	const productCardsRefs = useRef([]);

	const animateOnScroll = () => {
		scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
		for (let i = 0; i < productsForAnimation.length; i += 1) {
			let prevImgEl = '';
			if (
				scrollPosition + offsetDifference >= productsForAnimation[i].offsetTop &&
				scrollPosition + offsetDifference < productsForAnimation[i].offsetBottom
			) {
				if (animatedProduct !== i + 1) {
					if (animatedProduct < i + 1) {
						// prevImgEl = document.getElementById(`product-img-${i}`);
						prevImgEl = refs.current[i];
					} else {
						// prevImgEl = document.getElementById(`product-img-${i + 2}`);
						prevImgEl = refs.current[i + 2];
					}
					setAnimatedProduct(i + 1);
					const imgEl = refs.current[i + 1];
					if (i % 2 !== 0) {
						// eslint-disable-next-line max-len
						circleElRef.current.style.transform = `translate3d(calc(100% + 60px),${productsForAnimation[i].offsetTopRelative}px, 0px)`;
					} else {
						circleElRef.current.style.transform = `translate3d(0,${productsForAnimation[i].offsetTopRelative}px, 0px)`;
					}
					if (prevImgEl) {
						prevImgEl.style.transform = 'scale(1)';
					}
					imgEl.style.transform = 'scale(1.2)';
				}
			}
		}
		if (
			scrollPosition + offsetDifference < productsForAnimation[0].offsetTop ||
			scrollPosition + offsetDifference >= productsForAnimation[productsForAnimation.length - 1].offsetBottom
		) {
			setAnimatedProduct(0);
			refs.current[1].style.transform = 'scale(1)';
			refs.current[productsForAnimation.length].style.transform = 'scale(1)';
		}
	};

	const handleMouseMoveOnSlider = (e) => {
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		customCursor.style.top = `${offsetY}px`;
		customCursor.style.left = `${offsetX}px`;
	};

	function offset(el) {
		const rect = el.getBoundingClientRect();
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return rect.top + scrollTop;
	}

	useEffect(() => {
		const getCustomCursor = document.getElementById('custom-cursor-news');
		setCustomCursor(getCustomCursor);

		offsetDifference = window.innerHeight / 2;
		productData.forEach((item, key) => {
			const el = productCardsRefs.current[key];
			if (el) {
				// const viewportOffset = el.getBoundingClientRect();
				const elOffsetTop = offset(el);
				const elOffsetTopRelative = el.offsetTop;
				const elOffsetBottom = elOffsetTop + el.offsetHeight;
				productsForAnimation.push({
					id: item.remoteId,
					offsetTop: elOffsetTop,
					offsetBottom: elOffsetBottom,
					offsetTopRelative: elOffsetTopRelative,
				});
			}
		});

		if (!isMobile) {
			window.addEventListener('scroll', animateOnScroll);
		}
		return () => {
			if (!isMobile) {
				window.removeEventListener('scroll', animateOnScroll);
			}
		};
	}, [animatedProduct]);

	return (
		<PageContainer className="full-width">
			<StyledModuleWrapper>
				<StyledModuleWrapperForAnimation>
					{productData.map((item, key) => {
						const uri = item?.uri || '';
						const productName = item?.wizytowkaNazwa || '';
						const productDescription = item?.wizytowkaOpis || '';
						const productImage = item?.wizytowkaZdjecie?.[0]?.url || '';
						const productId = item?.remoteId || '';

						return (
							<StyledProductsWrapper>
								<StyledProductCard
									data-cursor="green-circle"
									onMouseMove={(e) => handleMouseMoveOnSlider(e)}
									className="product-card"
									id={productId}
									/* eslint-disable-next-line no-return-assign */
									ref={(product) => (productCardsRefs.current[key] = product)}
								>
									<StyledLink to={`/${getSlug(uri, activeSite)}`}>
										<StyledImageWrapper>
											<StyledImageBg
												bgImg={productImage}
												id={`product-img-${key + 1}`}
												/* eslint-disable-next-line no-return-assign */
												ref={(element) => (refs.current[key + 1] = element)}
											/>
										</StyledImageWrapper>
									</StyledLink>
									<StyledLink to={`/${getSlug(uri, activeSite)}`}>
										<StyledTextBelowImageWrapper className="header-description-wrapper">
											<StyledNumberWrapper>
												<TextHeader2 className="header">{`_${key + 1}`}</TextHeader2>
											</StyledNumberWrapper>
											<StyledDescriptionWrapper>
												<StyledTextHeader2Wrapper className="header">
													<TextHeader2>{productName}</TextHeader2>
												</StyledTextHeader2Wrapper>
												<TextDescription2 className="mb-50 description">{productDescription}</TextDescription2>
											</StyledDescriptionWrapper>
										</StyledTextBelowImageWrapper>
									</StyledLink>
								</StyledProductCard>
							</StyledProductsWrapper>
						);
					})}
					<CircleAnimationElement ref={circleElRef} />
				</StyledModuleWrapperForAnimation>
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default CategoriesProducts;
